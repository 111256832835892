export default {
  state: {
    attendanceQuery: null,
    // payrollQuery: null,
    // payrollAdditionalQuery: null,
    // payrollConfigQuery: null,
    bpjsProvisionQuery: null,
    // payrollComponentQuery: null,
    // payrollTemplateQuery: null,
    // overtimeSettingQuery: null,
    // shiftSettingQuery: null,
    // shiftScheduleQuery: null,
    matrixApprovalQuery: null,
    bpjsTemplateQuery: null
    // isLoginPayroll: false
  },
  mutations: {
    setAttendacePageQuery(state, payload) {
      state.attendanceQuery = payload
    },
    // setPayrollPageQuery(state, payload) {
    //   state.payrollQuery = payload
    // },
    // setPayrollAdditionalPageQuery(state, payload) {
    //   state.payrollAdditionalQuery = payload
    // },
    // setPayrollConfigPageQuery(state, payload) {
    //   state.payrollConfigQuery = payload
    // },
    setBpjsProvisionPageQuery(state, payload) {
      state.bpjsProvisionQuery = payload
    },
    // setPayrollComponentPageQuery(state, payload) {
    //   state.payrollComponentQuery = payload
    // },
    // setPayrollTemplatePageQuery(state, payload) {
    //   state.payrollTemplateQuery = payload
    // },
    // setOvertimeSettingPageQuery(state, payload) {
    //   state.overtimeSettingQuery = payload
    // },
    // setShiftSettingPageQuery(state, payload) {
    //   state.shiftSettingQuery = payload
    // },
    // setShiftSchedulePageQuery(state, payload) {
    //   state.shiftScheduleQuery = payload
    // },
    setMatrixApprovalPageQuery(state, payload) {
      state.matrixApprovalQuery = payload
    },
    setBpjsTemplatePageQuery(state, payload) {
      state.bpjsTemplateQuery = payload
    }
    // setLoginPayroll(state, payload) {
    //   state.isLoginPayroll = payload
    // }
  },
  actions: {},
  getters: {
    getAttendacePageQuery(state) {
      return state.attendanceQuery
    },
    // getPayrollPageQuery(state) {
    //   return state.payrollQuery
    // },
    // getPayrollAdditionalPageQuery(state) {
    //   return state.payrollAdditionalQuery
    // },
    // getPayrollConfigPageQuery(state) {
    //   return state.payrollConfigQuery
    // },
    getBpjsProvisionPageQuery(state) {
      return state.bpjsProvisionQuery
    },
    // getPayrollComponentPageQuery(state) {
    //   return state.payrollComponentQuery
    // },
    // getPayrollTemplatePageQuery(state) {
    //   return state.payrollTemplateQuery
    // },
    // getOvertimeSettingPageQuery(state) {
    //   return state.overtimeSettingQuery
    // },
    // getShiftSettingPageQuery(state) {
    //   return state.shiftSettingQuery
    // },
    // getShiftSchedulePageQuery(state) {
    //   return state.shiftScheduleQuery
    // },
    getMatrixApprovalPageQuery(state) {
      return state.matrixApprovalQuery
    },
    getBpjsTemplatePageQuery(state) {
      return state.bpjsTemplateQuery
    }
    // getLoginPayroll(state) {
    //   return state.isLoginPayroll
    // }
  }
}
