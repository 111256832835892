<template>
  <div>
    <div
      v-if="textSlider"
      style="position: fixed; bottom: 10px; right: 0; z-index: 1; left: 0"
    >
      <marquee direction="left" scrollamount="10">
        <div class="blink d-flex align-center">
          <v-icon class="mr-3" color="orange" x-large>mdi-alert</v-icon>
          <div @click="downloadLink">
            <h6 class="textRed">PERINGATAN !!! KHUSUS ANDROID !!!</h6>
            <p class="textBlack" style="margin: 0; font-size: 16px">
              Applikasi HRIS akan dihapus dari Playstore pada 9 Maret 2025
            </p>
            <div class="d-flex">
              <p class="textBlack mr-3" style="margin: 0; font-size: 16px">
                Segera install aplikasi terbaru dari link berikut ini
              </p>
              <v-btn x-small type="button" color="black">
                <v-icon color="white" style="margin-right: 5px" small>
                  mdi-android
                </v-icon>
                <span style="color: white; font-size: 8px"> download</span>
              </v-btn>
            </div>
          </div>
        </div>
      </marquee>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    apkDownloadLink: 'https://e-sanqua.sanquawater.co.id/download/android',
    textSlider: false
  }),
  mounted() {
    if (process.env.VUE_APP_TEXT_SLIDER == 'true') {
      this.textSlider = true
    }
  },
  methods: {
    downloadLink() {
      window.open(this.apkDownloadLink, '_blank')
    }
  }
}
</script>

<style scoped>
.blink {
  /* animation: blinker 1.6s linear infinite; */
  font-family: sans-serif;
  /* background: rgba(255, 255, 255, 0.767); */
  border-radius: 10px;
}
.textRed {
  color: red;
  font-family: sans-serif;
  font-weight: bold;
}
.textBlack {
  color: rgb(24, 24, 24);
  font-family: sans-serif;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
