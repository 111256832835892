<template>
  <v-app>
    <v-main class="app-main">
      <div style="height: 100%; background: rgba(255, 255, 255, 0.95)">
        <nav
          v-if="this.$route.meta.requiresAuth"
          style="position: sticky; top: 0; z-index: 4"
        >
          <Navigation chosenpage="eSanqua" @setDrawer="drawerSet" page="home" />
        </nav>
        <router-view />
        <v-navigation-drawer v-model="drawer" fixed height="100vh" temporary>
          <Drawer />
        </v-navigation-drawer>
        <!-- <v-footer
          padless
          style="width:100%; background:transparent; z-index:1;"
        >
          <Footer />
        </v-footer> -->
        <!-- <button @click="getFcmToken">gettoke</button> -->
      </div>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'
import Navigation from './components/navigation/Navigation'
import Drawer from './components/navigation/Drawer'
// import Footer from './components/navigation/footer'
import buildType from '../src/services/buildType'
import { mapActions, mapMutations } from 'vuex'

// import { getMessaging, onMessage } from 'firebase/messaging'

export default {
  name: 'App',
  data: () => ({
    oAuth: buildType.apiURL('oauth'),
    token: localStorage.getItem('token'),
    refresh_token: localStorage.getItem('refresh_token'),

    drawer: false,
    snackbar: false,
    text: null,
    timeout: 2000,
    multiLine: true
  }),
  components: {
    // Footer,
    Navigation,
    Drawer
  },
  computed: {},
  created() {
    console.log(`Application running as: "${process.env.VUE_APP_BUILD_TYPE}"`)
    this.verifyToken()
    // console.log(navigator.userAgent)
    this.interceptorRequest()
    this.interceptorResponse()
      .then(async (res) => {
        if (!this.$route.meta.requiresPublic) {
          // if (res.data.token_data !== undefined) {
          //   if (res.data.token_data.status_code === '00') {
          //     if (res.data.token_data.err_msg.name === 'TokenExpiredError') {
          //       console.log('TokenExpiredError')
          //       await this.verifyToken()
          //     }
          //     if (res.data.token_data.err_msg.name === 'JsonWebTokenError') {
          //       console.log('JsonWebTokenError')
          //       // this.logout()
          //     }
          //   }
          //   return console.log('Token data exist')
          // }
          // if (res.data.status_code === '-99') {
          //   if (res.data.status_msg === 'TokenExpiredError') {
          //     console.log('TokenExpiredError')
          //     await this.verifyToken()
          //   }
          //   if (res.data.status_msg === 'JsonWebTokenError') {
          //     console.log('JsonWebTokenError')
          //     // this.logout()
          //   }
          //   return console.log('Token data undefined')
          // }
        }
      })
      .catch((err) => {
        console.log(err)
        // if (!this.$route.meta.requiresPublic) {
        //     this.logout()
        // }
      })
  },
  mounted() {
    const self = this
    const channel = new BroadcastChannel('sw-messages')
    const channel2 = new BroadcastChannel('onclick-messages')
    channel.onmessage = function (event) {
      console.log(event.data.data)

      self.loadNotification()
      self.loadPermissionApprovalNeeds()
      self.loadLeaveApprovalNeeds()
    }
    channel2.onmessage = function (event) {
      // console.log(event.data.data)
      // const message = event.data.data
      // if (message.module === 'leave') {
      //   setTimeout(() => {
      //     self.$router.push(
      //       `/hr/leave/detail/${message.document_id}?redirect_from=inappnotification`
      //     )
      //   }, 100)
      // }
      // if (message.module === 'permission') {
      //   setTimeout(() => {
      //     self.$router.push(
      //       `/hr/permission/detail/${message.document_id}?redirect_from=inappnotification`
      //     )
      //   }, 100)
      // }
    }
  },
  methods: {
    ...mapActions([
      'interceptorRequest',
      'interceptorResponse',
      'logout',
      'loadNotification',
      'loadPermissionApprovalNeeds',
      'loadLeaveApprovalNeeds'
    ]),
    ...mapMutations(['setToken']),
    drawerSet(val) {
      this.drawer = val
    },
    async verifyToken() {
      const token = localStorage.getItem('token')
      const instance = axios.create()
      await instance
        .get(
          `${this.oAuth}user/verify_token?token=${token}&method=conventional`
        )
        .then((res) => {
          if (res.data.status_code === '-99') {
            if (res.data.status_msg === 'TokenExpiredError') {
              this.refreshToken()
            }
            if (res.data.status_msg === 'JsonWebTokenError') {
              this.logout()
            }
          }
          return null
        })
        .catch((err) => {
          console.log(err)
          // this.logout()
          return null
        })
    },
    async refreshToken() {
      const token = localStorage.getItem('token')
      const refresh_token = localStorage.getItem('refresh_token')
      const instance = axios.create()
      const payload = {
        token: token,
        refresh_token: refresh_token,
        user_id: JSON.parse(localStorage.getItem('vuex')).auth.userProfile
          .user_id
      }
      await instance
        .post(`${this.oAuth}user/refresh_token`, payload)
        .then((res) => {
          if (res.data.status_code === '00') {
            localStorage.setItem('token', res.data.new_access_token)
            localStorage.setItem('refresh_token', res.data.refresh_token)
            this.setToken(res.data.new_access_token)
            window.location.reload()
          }
        })
        .catch((err) => {
          console.log(err)
          // this.logout()
          return null
        })
    }
  }
}
</script>
<style scoped lang="scss">
.app-main {
  background-image: url('./assets/image/e-sanqua-logo-1.jpg'),
    url('./assets/image/background1.png');
  background-size: 250px 350px, cover;
  background-repeat: no-repeat, no-repeat;
  background-position: center 120px, center;
}
</style>
