import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import archive from './modules/archive/archive'
import asset from './modules/asset/asset'
import auth from './modules/auth/auth'
import cart from './modules/e-Catalogue/cart'
import catalogue from './modules/e-Catalogue/catalogue'
import home from './modules/home/home'
import roomBooking from './modules/roomBooking/roomBooking'
import leave from './modules/hr/leave'
import permission from './modules/hr/permission'
import notification from './modules/notification/notification'
import employee from './modules/hr/employee'
import oee from './modules/oee/oee'
import project from './modules/e-Catalogue/project'
// import overtime from './modules/hr/overtime'
import urlQuery from './modules/hr/urlQuery'
// import payreq from './modules/e-Catalogue/payreq'
// import goodsReceipt from './modules/e-Catalogue/goodsreceipt'
// import pjca from './modules/e-Catalogue/pjca'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    home,
    auth,
    archive,
    asset,
    cart,
    catalogue,
    roomBooking,
    leave,
    permission,
    notification,
    employee,
    oee,
    project,
    // overtime,
    urlQuery
    // payreq,
    // goodsReceipt,
    // pjca
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  plugins: [
    createPersistedState({
      paths: [
        'auth.login',
        'auth.userProfile',
        'home.tabs',
        'home.permission',
        'catalogue.catalogueDetail',
        'catalogue.param',
        'roomBooking.bookingDetail',
        'roomBooking.roomDropdown',
        'roomBooking.roomBookingId',
        'archive.loanDocId',
        'cart.savedData',
        'cart.fpbId',
        'cart.fpbItems',
        'asset.items',
        'asset.assetLotCompanyId',
        'employee.urlQuery',
        'oee.oeeId',
        // 'overtime.urlQuery',
        'leave.urlQuery',
        'permission.urlQuery',
        'urlQuery'
        // 'payreq.urlQuery',
        // 'goodsReceipt.urlQuery',
        // 'pjca.urlQuery'
      ],
      reducer(val) {
        if (!val.auth.login) {
          return {}
        }
        return val
      }
      // storage: {
      //   getItem: key => Cookies.get(key),
      //   // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
      //   setItem: (key, value) =>
      //     Cookies.set(key, value, { expires: 3, secure: true }),
      //   removeItem: key => Cookies.remove(key)
      // }
      // storage: window.localStorage
    })
  ]
})
